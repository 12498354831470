import {
  DocumentCategory,
  DocumentEntityType,
  DocumentTypeFactory,
} from "neword-core";
import { Dialog } from "primereact/dialog";
import React, { useState, useCallback, useMemo } from "react";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import documentService from "../../../core/services/document.service";
import useNavigator from "../../../hooks/useNavigator";
import { websiteState } from "../../../state/websitesState";
import DocBoxes from "../../document/components/DocBoxes";
import documentsDictionary from "../../document/documentsDictionary";
import WelcomeBackCard from "../../website/overview/WelcomeBackCard";
import CardFilterItem from "../../website/overview/cardsFilter/CardFilterItem";
import ContentCard from "../../website/overview/contentCards/ContentCard";
import { platformTypeText } from "../../website/overview/contentCards/cardTypes";
import InputStyle from "./InputStyle";
import { ReactComponent as PopularIcon } from "../../../assets/Icons/overviewFilterIcons/popular_icon.svg";
import { ReactComponent as BlogIcon } from "../../../assets/Icons/overviewFilterIcons/blog_icon.svg";
import { ReactComponent as AdsIcon } from "../../../assets/Icons/overviewFilterIcons/ads_icon.svg";
import { ReactComponent as WebsiteIcon } from "../../../assets/Icons/overviewFilterIcons/website_icon.svg";
import { ReactComponent as MarketingIcon } from "../../../assets/Icons/overviewFilterIcons/marketing_icon.svg";
import { ReactComponent as SocialIcon } from "../../../assets/Icons/overviewFilterIcons/social_icon.svg";
import { InputText } from "primereact/inputtext";
import ModalHeader from "../../modals/ModalHeader";

const Title = styled.h1`
  color: white;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 2.5rem */
  letter-spacing: -0.075rem;
  width: auto;
  text-align: center;
`;

const SubTitle = styled.h2`
  color: white;
  text-align: center;
  font-weight: 300;
`;

const TypeTitle = styled.div`
  color: var(--Main-TitleColor, #0a2540);
  leading-trim: both;
  text-edge: cap;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1.5rem */
  letter-spacing: -0.045rem;
  margin: 10px 0;
`;

const DialogStyled = styled(Dialog)`
  width: 80%;
  height: 90%;
  padding: 0;
  .p-dialog-header {
    padding: 0;
  }
`;

const HeaderWrapper = styled.div``;

const SearchInputStyled = styled(InputText)`
  width: 300px;
  &,
  &:hover {
    border: solid 1px var(--text-color) !important;
  }
`;

const MenuWrapper = styled.div`
  padding: 30px;
  background: var(--light-bg);
  height: 100%;
`;

const ScrollSection = styled.div`
  overflow-y: auto;
  max-height: 70vh;
`;

const CreateDocModal: React.FC<{ onHide: () => void }> = ({ onHide }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { websiteId } = useParams();
  const navigate = useNavigator();
  const [selectedCategory, setSelectedCategory] = useState<
    DocumentCategory | "all"
  >("all");
  const website = useRecoilValue(websiteState(websiteId as string));
  const createDoc = useCallback(
    async (type: DocumentEntityType) => {
      if (!documentsDictionary[type]) return;
      const docId = await documentService.createDocument(
        type,
        websiteId as string
      );
      navigate(`/document/${docId}`);
    },
    [websiteId]
  );

  const docBoxesFiltered = useMemo(
    () =>
      DocBoxes.filter((doc) => {
        if (searchTerm) {
          return doc.title.includes(searchTerm);
        }

        if (selectedCategory) {
          return (
            selectedCategory === "all" ||
            DocumentTypeFactory[doc.type].category === selectedCategory
          );
        }
        return true;
      }).sort(),
    [searchTerm, selectedCategory]
  );

  return (
    <DialogStyled
      visible
      onHide={onHide}
      header={<ModalHeader OnClose={onHide} right />}
      modal={true}
      draggable={false}
      closable={false}
    >
      <br />
      <div className="grid">
        <div className="col-2 p-0">
          <MenuWrapper>
            <div className="grid">
              <div className="col-12">
                <CardFilterItem
                  onClick={() => setSelectedCategory("all")}
                  title={"הכל"}
                  icon={<PopularIcon />}
                  selected={selectedCategory === "all"}
                />
              </div>
              <div className="col-12">
                <CardFilterItem
                  onClick={() => setSelectedCategory(DocumentCategory.BLOG)}
                  title={"בלוג"}
                  icon={<BlogIcon />}
                  selected={selectedCategory === DocumentCategory.BLOG}
                />
              </div>
              <div className="col-12">
                <CardFilterItem
                  onClick={() => setSelectedCategory(DocumentCategory.PAID)}
                  title={"פרסום ממומן"}
                  icon={<AdsIcon />}
                  selected={selectedCategory === DocumentCategory.PAID}
                />
              </div>
              <div className="col-12">
                <CardFilterItem
                  onClick={() => setSelectedCategory(DocumentCategory.WEB)}
                  title={"אתרים"}
                  icon={<WebsiteIcon />}
                  selected={selectedCategory === DocumentCategory.WEB}
                />
              </div>
              <div className="col-12">
                <CardFilterItem
                  onClick={() => setSelectedCategory(DocumentCategory.MAILING)}
                  title={"דיוור"}
                  icon={<MarketingIcon />}
                  selected={selectedCategory === DocumentCategory.MAILING}
                />
              </div>
              <div className="col-12">
                <CardFilterItem
                  onClick={() =>
                    setSelectedCategory(DocumentCategory.SOCIAL_MEDIA)
                  }
                  title={"סושיאל"}
                  icon={<SocialIcon />}
                  selected={selectedCategory === DocumentCategory.SOCIAL_MEDIA}
                />
              </div>
            </div>
          </MenuWrapper>
        </div>
        <div className="col-10">
          <HeaderWrapper>
            <SearchInputStyled
              placeholder="חפשו לדוגמה ״פוסט לאינסטגרם״"
              onChange={(e: any) => {
                setSearchTerm(e.target.value);
                if (selectedCategory) {
                  setSelectedCategory("all");
                }
              }}
              value={searchTerm}
            />
          </HeaderWrapper>
          <ScrollSection>
            <div className="grid w-full">
              {docBoxesFiltered.map((doc, index) => (
                <>
                  {doc.platformType !==
                    docBoxesFiltered[index - 1]?.platformType && (
                    <div className="col-12">
                      <TypeTitle>
                        {platformTypeText[doc.platformType]}
                      </TypeTitle>
                    </div>
                  )}
                  <div
                    onClick={() => createDoc(doc.type)}
                    key={index}
                    className="col-2"
                  >
                    <ContentCard
                      title={doc.title}
                      description={doc.description}
                      image={doc.image}
                      type={doc.platformType}
                      highlightTitleTerm={searchTerm}
                    />
                  </div>
                </>
              ))}
            </div>
          </ScrollSection>
        </div>
      </div>
      <div className="grid"></div>
    </DialogStyled>
  );
};

export default CreateDocModal;
