import React, { useRef, useState } from "react";
import Calendar from "react-calendar";
import { TileContentFunc, Value } from "react-calendar/dist/cjs/shared/types";
import styled from "styled-components";
import PostImage from "../assets/images/contentCardsImages/multiinsta.png";
import { Theme } from "../core/theme/theme";
import { ContentCardIcons } from "../components/website/overview/contentCards/iconMappings";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";

const CreateDocument = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  padding: 15px;
  border-radius: 8px;
  border: solid 1px var(--border-color);
  opacity: 0;
  cursor: pointer;

  &:hover {
    border-color: var(--primary-purple);
    color: var(--primary-purple);
  }
`;

const CalendarStyled = styled(Calendar)`
  width: 100%;
  border: none;
  font-family: "Assistant";

  .react-calendar__tile {
    min-height: 150px;
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
    border: solid 0.5px var(--border-color);
    display: flex;
    font-family: "Assistant";
    flex-direction: column;

    &:hover {
      ${CreateDocument} {
        opacity: 1;
      }
    }

    abbr {
      text-decoration: none;
      float: right;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      font-family: "Assistant";
    }
  }

  .react-calendar__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
  }

  .react-calendar__navigation__label {
    background: none;
    border: none;
    flex-grow: 0 !important;
    padding: 0 20px;
    pointer-events: none;
    font-weight: bold;
    text-transform: uppercase;
    font-family: "Open Sans", "open sans Hebrew";
  }

  .react-calendar__navigation__arrow {
    background: none;
    border: none;
    font-size: 16px;
    padding: 0 10px;
    font-weight: bold;
    cursor: pointer;
  }

  .react-calendar__month-view__weekdays__weekday {
    /* background: var(--light-bg); */
    display: flex;
    justify-content: center;
    text-decoration: none !important;
    padding: 10px;
    border-bottom: ${Theme.colors.border};
    margin-top: 10px;
    abbr {
      text-decoration: none;
      text-transform: uppercase;
      font-family: "Open Sans", "open sans Hebrew";
      font-size: 12px;
      color: var(--text-color);
    }
  }

  .past-date {
    background-color: var(--light-bg); /* Styling for past dates */
  }

  .react-calendar__tile--now abbr {
    background-color: var(--purple-opacity);
    color: var(--primary-purple);
    font-family: "Assistant";
    border: solid 1px var(--primary-purple);
  }
`;

const TimeWrapper = styled.div`
  font-size: 0.8em;
  font-weight: bold;
  color: var(--primary-purple);
`;

const TileWrapper = styled.div<{ imageUrl?: string }>`
  cursor: pointer;
  background: white;
  margin-top: 10px;
  border: solid 1px var(--border-color);
  color: ${Theme.colors.text};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: ${(props) => `url(${props.imageUrl})`};
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  font-family: "Open Sans", "open sans Hebrew";
  width: 100%; /* Allow the tile to take the full width */
  padding: 10px; /* Add some padding */
  box-sizing: border-box;

  &:hover {
    border: solid 1px var(--primary-purple);
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

const PlatformsWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
  .icon {
    width: 15px;
    height: 15px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  img {
    width: 100%;
    height: 80px;
    object-fit: cover;
  }
`;

const PostText = styled.div`
  font-size: 12px;
  color: var(--text-color);
  margin-top: 10px;
`;

const MenuStyled = styled(Menu)`
  .p-menuitem-icon {
    margin-right: 0px !important;
    margin-left: 10px;
  }
`;

function MyCalendar() {
  const [value, setValue] = useState<Value>(new Date());
  const menuRefs = useRef<{ [key: number]: React.RefObject<Menu> }>({}); // Store refs for all menus

  const items: MenuItem[] = [
    {
      label: "אפשרויות",
      items: [
        {
          label: "אישור פוסט",
          icon: "pi pi-check",
        },
        {
          label: "פרסם עכשיו",
          icon: "pi pi-upload",
        },
        {
          label: "הסתר מהלקוחות",
          icon: "pi pi-eye-slash",
        },
      ],
    },
  ];

  // Example: List of days and events
  const events = [
    {
      date: new Date(2024, 8, 10),
      event: "Meeting with team",
      time: "10:45",
      platforms: [ContentCardIcons.THREADS.icon],
    },
    {
      date: new Date(2024, 8, 12),
      event: "פוסט מיתוגי של חברת ניו וורד",
      imageUrl: PostImage,
      time: "10:45",
      platforms: [
        ContentCardIcons.FACEBOOK.icon,
        ContentCardIcons.INSTAGRAM.icon,
      ],
    },
    {
      date: new Date(2024, 8, 15),
      event: "פוסט מיתוגי של חברת ניו וורד",
      imageUrl: PostImage,
      time: "12:00",
      platforms: [
        ContentCardIcons.LINKEDIN.icon,
        ContentCardIcons.INSTAGRAM.icon,
        ContentCardIcons.FACEBOOK.icon,
      ],
    },
  ];

  // Function to check if a date has an event
  const getEventsForDate = (date: Date) => {
    return events.filter(
      (event) => event.date.toDateString() === date.toDateString()
    );
  };

  const handleMenuToggle = (e: React.MouseEvent, index: number) => {
    if (!menuRefs.current[index]) return;

    menuRefs.current[index].current?.toggle(e); // Toggle the specific menu
  };

  const tileContent: TileContentFunc = ({ date, view }) => {
    if (view === "month") {
      const events = getEventsForDate(date);
      if (events.length > 0) {
        return (
          <>
            {events.map((event, index) => {
              if (!menuRefs.current[index]) {
                menuRefs.current[index] = React.createRef<Menu>();
              }

              return (
                <div className="w-full">
                  <MenuStyled
                    model={items}
                    popup
                    ref={menuRefs.current[index]}
                    popupAlignment="right"
                    id={`popup_menu_left_${index}`}
                  />
                  <TileWrapper
                    key={index}
                    onClick={(e) => {
                      console.log(`TopWrapper clicked for index ${index}`);
                      handleMenuToggle(e, index);
                    }}
                    aria-controls={`popup_menu_left_${index}`}
                    aria-haspopup
                  >
                    <TopWrapper>
                      <TimeWrapper>{event.time || "10:45"}</TimeWrapper>
                      <PlatformsWrapper>
                        {event.platforms.map((PlatformIcon, iconIndex) => (
                          <PlatformIcon key={iconIndex} className="icon" />
                        ))}
                      </PlatformsWrapper>
                    </TopWrapper>
                    <ImageWrapper>
                      {event.imageUrl && (
                        <img src={event.imageUrl} alt="Event Image" />
                      )}
                    </ImageWrapper>
                    <PostText>
                      {event.event.length > 30
                        ? `${event.event.slice(0, 30)}...`
                        : event.event}
                    </PostText>
                  </TileWrapper>
                </div>
              );
            })}
          </>
        );
      }
    }
    return (
      <CreateDocument>
        <span>צור מסמך חדש</span>
      </CreateDocument>
    );
  };

  // Function to check if a date is before the current date
  const tileClassName = ({ date }: { date: Date }) => {
    const today = new Date();
    // If the tile date is before today, return the 'past-date' class
    if (date < today && date.toDateString() !== today.toDateString()) {
      return "past-date";
    }
    return "";
  };

  return (
    <CalendarStyled
      onChange={(val) => setValue(val)}
      value={value}
      tileContent={tileContent} // Custom content
      calendarType="hebrew"
      tileClassName={tileClassName} // Add class to past dates
      locale="he"
    />
  );
}

export default MyCalendar;
