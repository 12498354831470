import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import styled from "styled-components";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import { ReactComponent as Neword } from "../../../assets/Icons/BlueIcon.svg";
import { ReactComponent as WebsiteLogo } from "../../../assets/Icons/WebsiteLogo.svg";
import { BlockProps } from "./types/dictionaryProps";
import { RefProps } from "./types/refProps";
import EditableTextArea from "../components/EditableTextArea";

const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AcountText = styled.div`
  font-weight: 700;
  font-size: 12.21px;
  display: flex;
  flex-direction: row-reverse;
`;

const LeftTopMenu = styled.div`
  display: flex;
  align-items: center;
`;
const SubjectWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const AnswerInputWrapper = styled.div`
  border-radius: 8px;
  background: var(--Card-Background, #f6f9fc);
  padding: 10px 20px;
  display: flex;
  align-items: center;
`;
const QuastionInputWrapper = styled.div`
  border-radius: 8px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
`;

const BoldText = styled.div`
  text-align: right;
  font-family: "Noto Sans Hebrew";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin-left: 12px;
  white-space: nowrap;
  display: flex;
  min-width: 45px;
`;

const WbsiteUrl = styled.div`
  font-family: "Noto Sans Hebrew";
  font-size: 12.211px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 13.432px */
  letter-spacing: -0.366px;
`;
const WebsiteFaqBlock = forwardRef<RefProps, BlockProps>(
  ({ isEditing, blockIndex, handleBlur }, ref) => {
    const { websiteId } = useParams();
    const website = useRecoilValue(websiteState(websiteId as string));

    const wrapperRef = useRef<HTMLDivElement>(null);

    const { getValues, control } =
      useFormContext<DocumentEntity<DocumentEntityType.WEBSITE_FAQ>>();

    useImperativeHandle(ref, () => ({
      onCopyCustom: () => {
        const faq = getValues(`output.${blockIndex}.faqEntries`);
        let copyText = "";
        faq.map((field) => {
          copyText += `שאלה: ${field.question}  \n תשובה: ${field.answer}`;
        });
        return copyText;
      },
    }));

    const { fields } = useFieldArray<
      DocumentEntity<DocumentEntityType.WEBSITE_FAQ>
    >({
      control: control,
      name: `output.${blockIndex}.faqEntries`,
    });

    return (
      <Wrapper ref={wrapperRef}>
        <TopSection>
          <WebsiteLogo />
          <LeftTopMenu>
            <AcountText className="ml-1">{website?.name}</AcountText>
            <WbsiteUrl>{website?.domain}</WbsiteUrl>{" "}
          </LeftTopMenu>
        </TopSection>

        {fields.map((field, faqIndex) => (
          <React.Fragment key={field.id}>
            <SubjectWrapper className="mt-3">
              <BoldText className="h-full "> שאלה:</BoldText>
              <QuastionInputWrapper className="w-full">
                <EditableTextArea
                  fieldName={`output.${blockIndex}.faqEntries.${faqIndex}.question`}
                  blockIndex={blockIndex}
                  isEditing={isEditing}
                  style={{ fontSize: "14px", fontWeight: 700 }}
                />
              </QuastionInputWrapper>
            </SubjectWrapper>
            <SubjectWrapper className="mt-3">
              <BoldText className="h-full"> תשובה:</BoldText>
              <AnswerInputWrapper className="w-full">
                <EditableTextArea
                  fieldName={`output.${blockIndex}.faqEntries.${faqIndex}.answer`}
                  blockIndex={blockIndex}
                  isEditing={isEditing}
                  handleBlur={handleBlur}
                />
              </AnswerInputWrapper>
            </SubjectWrapper>
          </React.Fragment>
        ))}
      </Wrapper>
    );
  }
);

export default WebsiteFaqBlock;
