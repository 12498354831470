import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import AudienceSelect from "../../common/form/AudienceSelect";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocumentTone from "../components/DocumentTone";
import BlogPostOpinionDescription from "./formComponentes/BlogPostOpinionDescription";
import { AccordionProps } from "./types/AccordionProps";
import GenericDescriptionInput from "./formComponentes/GenericDescriptionInput";

export const smsAccordions: AccordionProps[] = [
  {
    title: "תיאור כללי לתוכן המייל",
    content: (
      <div className="w-full step-2-wt">
        <GenericDescriptionInput<DocumentEntity<DocumentEntityType.SMS_CONTENT>>
          name="inputParams.description"
          label="המסר המרכזי?"
          placeholder="מהו המסר המרכזי או ההצעה שאתה רוצה להעביר במייל קבלת הפנים?"
        />
        <GenericDescriptionInput<DocumentEntity<DocumentEntityType.SMS_CONTENT>>
          name="inputParams.cta"
          label={"איזו פעולה תרצה שהנמען יבצע לאחר קריאת הסמס?"}
          placeholder="לדוגמה ״לינק לפלטפורמה שלנו״"
          hideRephrase
          inputStyle={{ height: 50 }}
        />{" "}
      </div>
    ),
    fieldNames: [
      "inputParams.cartItems",
      "inputParams.supportContact",
      "inputParams.incentives",
    ],
  },

  {
    title: "הגדרות כלליות לסמס",
    content: (
      <div className="w-full step-2-wt">
        <GenericDescriptionInput<
          DocumentEntity<DocumentEntityType.FLASH_SALE_SMS>
        >
          name="inputParams.legalInformation"
          label="מידע והצהרות (אופציונלי)"
          placeholder="כל מידע משפטי או הצהרות שצריך לכלול, כגון תנאים והגבלות או הוראות לביטול הסכמה."
        />
        <GenericDescriptionInput<
          DocumentEntity<DocumentEntityType.FLASH_SALE_SMS>
        >
          name="inputParams.additionalInformation"
          label="מידע והצהרות (אופציונלי)"
          placeholder="כל מידע נוסף שתרצו להוסיף"
        />
      </div>
    ),
  },

  {
    title: "קהל יעד",
    content: (
      <AudienceSelect<
        DocumentEntity<DocumentEntityType.CART_ABANDONMENT_EMAIL>
      > fieldName="inputParams.audienceId" />
    ),
    fieldNames: ["inputParams.audienceId"],
  },
  {
    title: "סגנון כתיבה",
    content: (
      <>
        <small className="pr-2 mb-3">* ניתן לבחור יותר מאחד</small>
        <DocumentTone<DocumentEntity<DocumentEntityType.CART_ABANDONMENT_EMAIL>>
          fieldName="inputParams.tone"
          threeColumn={true}
        />
      </>
    ),
    fieldNames: ["inputParams.tone"],
  },
];
