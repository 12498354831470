import { AppIntegration } from "../entities/appIntegration";
import { FacebookPage } from "../entities/facebookPage";
import { ServiceBase } from "./service.base";

class AppIntegrationService extends ServiceBase {
  prefix = "/app-integration";

  getAppIntegrationsByWebsiteId(websiteId: string) {
    return this.get<AppIntegration[]>(`/${websiteId}`);
  }

  updateAppIntegrationByWebsiteId(id: string, data: Partial<AppIntegration>) {
    return this.patch<AppIntegration>(`/${id}`, data);
  }

  createAppIntegrationByWebsiteId(
    data: Omit<AppIntegration, "id" | "createdAt" | "updatedAt">
  ) {
    return this.post<AppIntegration>(`/`, data);
  }

  getFacebookPages(token: string) {
    return this.get<FacebookPage[]>(`/facebook/pages/${token}`);
  }
}

const appIntegrationService = new AppIntegrationService();
export default appIntegrationService;
