import styled from "styled-components";
import { useParams } from "react-router";
import FormWrapper from "../components/document/FormWrapper";
import BlockSection from "../components/document/BlockSection";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  DocumentEntity,
  DocumentEntityType,
  DocumentTypeFactory,
} from "neword-core";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { documentState } from "../state/documentState";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import _, { throttle } from "lodash";
import documentService from "../core/services/document.service";
import DocumentTopMenu from "../components/document/components/DocumentTopMenu";
import DocumentWalkthrough from "../components/common/form/DocumentWalkthrough";
import usePromise from "../hooks/usePromise";
import Preloader from "../components/common/Preloader";
import PublishPostModal from "../components/modals/PublishPostModal";

const BodyWrapper = styled.div`
  display: flex;
  height: calc(100vh - 74px);
  //margin-right: 97px;
  flex-direction: row;
`;

const DocumentPageWrapper = () => {
  const [doc, setDoc] = useState<DocumentEntity<DocumentEntityType> | null>();
  const { documentId } = useParams<{ documentId: string }>();

  useEffect(() => {
    documentService.getDocumentById(documentId as string).then((doc) => {
      setDoc(doc);
    });
  }, [documentId]);

  return (
    <>{doc ? <DocumentPage doc={doc} setDoc={setDoc} /> : <Preloader />}</>
  );
};

const DocumentPage: React.FC<{
  doc: DocumentEntity<DocumentEntityType>;
  setDoc: SetStateAction<any>;
}> = ({ doc, setDoc }) => {
  const [showTour, setShowTour] = useState(false);

  const methods = useForm<DocumentEntity<typeof doc.type>>({
    defaultValues: doc,
  });

  const onOutputChange = async () => {
    const values = methods.getValues();

    const newDoc = {
      id: values.id,
      output: values.output,
      name: values.name,
    };
    await documentService.updateDocument(newDoc);
  };

  const submitDebounced = useCallback(throttle(onOutputChange, 100), [
    document,
  ]);
  const watchedValues = methods.watch(["output", "name"]);

  useEffect(() => {
    submitDebounced();

    return () => {
      submitDebounced.cancel();
    };
  }, [submitDebounced, watchedValues]);

  // useEffect(() => {
  //   const firstVisit = localStorage.getItem("visited") !== "true";
  //   if (firstVisit) {
  //     setShowTour(true);
  //     localStorage.setItem("visited", "true");
  //   }
  // }, []);

  const [PostModal, setPostModal] = useState(false);

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <DocumentTopMenu />
          <BodyWrapper>
            <FormWrapper setDoc={setDoc} />
            <BlockSection />
          </BodyWrapper>
        </form>
      </FormProvider>

      {/* <PublishPostModal onHide={() => setPostModal(false)} />ת */}
    </>
  );
};

export default DocumentPageWrapper;
