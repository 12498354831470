import React, { useState, ReactNode } from "react";
import styled from "styled-components";

type AccordionProps = {
  title: string;
  children: ReactNode;
  defaultOpen?: boolean; // Optional prop to set initial open state
  icon?: ReactNode;
};

const AccordionItem = styled.div`
  padding: 15px;
  border-radius: 8px;
  border: 1px solid var(--primary-purple, #e6e6e6);
  margin-top: 17px;

  background: var(--light-bg);
`;

const AccordionTitle = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  color: var(--primary-purple);
  padding: 10px;
  font-weight: bold;
  user-select: none; // Prevent text selection
  font-family: "Assistant";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 20.128px */
  letter-spacing: -0.549px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;

  &::after {
    content: "${(props) => (props.isActive ? "-" : "+")}";
    float: left;
    position: absolute;
    left: 10px;
    font-size: 18px;
  }
`;

const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dbcaf1;
  border-radius: 8px;
  svg {
    fill: var(--primary-purple);
    width: 15px;
    height: 15px;
  }
`;

const AccordionContent = styled.div`
  padding: 0px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease-in-out;
`;
const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  icon,
  defaultOpen = false,
}) => {
  const [isActive, setIsActive] = useState(defaultOpen);

  return (
    <AccordionItem>
      <AccordionTitle
        isActive={isActive}
        onClick={() => setIsActive(!isActive)}
      >
        <IconWrapper>{icon}</IconWrapper>
        {title}
      </AccordionTitle>
      <AccordionContent style={{ maxHeight: isActive ? "1000px" : "0" }}>
        {children}
      </AccordionContent>
    </AccordionItem>
  );
};

export default Accordion;
