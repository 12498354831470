import {
  Controller,
  Path,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";

import {
  DocumentTypeFactory,
  DocumentEntityType,
  DocumentEntity,
} from "neword-core";

import GenericDescriptionInput from "./GenericDescriptionInput";

function CartAbandonmentMailDescription() {
  return (
    <div className="w-full step-2-wt">
      <GenericDescriptionInput<
        DocumentEntity<DocumentEntityType.CART_ABANDONMENT_EMAIL>
      >
        name="inputParams.incentives"
        label="האם יש תמריצים שאתה יכול להציע כדי לעודד לקוחות להשלים את הרכישה שלהם?"
        placeholder="תתארו את המבצע ואת ההטבות שאתם נותנים ללקוחות שלכם??"
      />

      <GenericDescriptionInput<
        DocumentEntity<DocumentEntityType.CART_ABANDONMENT_EMAIL>
      >
        name="inputParams.cartItems"
        label="מהם הפרטים של המוצרים שנותרו בעגלה??"
        placeholder="תתארו את הפריטים שנשכחו בעגלה"
        hideRephrase
      />
      <GenericDescriptionInput<
        DocumentEntity<DocumentEntityType.CART_ABANDONMENT_EMAIL>
      >
        name="inputParams.customerSupport"
        label="כיצד לקוחות יכולים לפנות אם יש להם שאלות או זקוקים לסיוע?"
        placeholder={`לדוגמה: ״דוא"ל: support@yourstore.com״, ״לייב צ'אט: זמין באתר שלנו״
        ״מספר טלפון לתמיכת לקוחות: 1-800-555-1234״
        `}
        hideRephrase
      />
    </div>
  );
}

export default CartAbandonmentMailDescription;
