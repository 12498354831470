import {
  Controller,
  Path,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";

import {
  DocumentTypeFactory,
  DocumentEntityType,
  DocumentEntity,
} from "neword-core";

import GenericDescriptionInput from "./GenericDescriptionInput";

function BlogPostOpinionDescription() {
  return (
    <div className="w-full step-2-wt">
      <GenericDescriptionInput<
        DocumentEntity<DocumentEntityType.BLOG_POST_OPINION>
      >
        name="inputParams.blogTopic"
        label="נושא הבלוג פוסט"
        placeholder="מה הנושא עליו תרצו לדבר בבלוג פוסט?"
        inputStyle={{ height: 45 }}
      />
      <GenericDescriptionInput<
        DocumentEntity<DocumentEntityType.BLOG_POST_OPINION>
      >
        name="inputParams.blogOpinion"
        label="הדעה שלכם או המסר שתרצו להעביר"
        placeholder="תתארו בצורה רחבה את הדעה או את המסר שתרצו להעביר לקוראים, במידה ואתם יכולים, ספרו על מקרה אישי או חוויה שקשורה לעמדה שלכם בנושא "
      />
      <GenericDescriptionInput<
        DocumentEntity<DocumentEntityType.BLOG_POST_OPINION>
      >
        name="inputParams.description"
        label="בריף של הבלוג פוסט"
        placeholder="תתארו בצורה רחבה את נושא הבלוג פוסט, פסקאות שחשובות לכם שיהיו"
      />
    </div>
  );
}

export default BlogPostOpinionDescription;
