import {
  Controller,
  Path,
  useController,
  useFormContext,
} from "react-hook-form";
import styled from "styled-components";
import React, {
  StyleHTMLAttributes,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import { useRecoilValue } from "recoil";
import { documentState } from "../../../state/documentState";
import { useParams } from "react-router";

import { DocumentEntityType, DocumentEntity } from "neword-core";

const EditableInput = styled.textarea<{ isEditing: boolean }>`
  border: ${({ isEditing }) =>
    isEditing ? "1px solid var(--title-color)" : "none"};
  background: transparent;
  outline: none;
  width: 100%;
  border-radius: 6px;
  height: auto;
  font-family: inherit;
  padding: ${({ isEditing }) => (isEditing ? "10px" : "none")};
  letter-spacing: -0.3px;
  resize: none;
  font-size: 16px;
  display: flex;
  color: var(--title-color);
  &:focus {
    outline: none;
  }
`;

interface Props<T extends DocumentEntityType> {
  isEditing: boolean;
  blockIndex: number;
  handleBlur?: (blockIndex: number) => void;
  fieldName: Path<DocumentEntity<T>>;
  style?: React.CSSProperties;
}

function EditableTextArea<T extends DocumentEntityType>({
  blockIndex,
  isEditing,
  handleBlur,
  fieldName,
  style,
}: Props<T>) {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { control, watch } = useFormContext<DocumentEntity<T>>();

  const { field } = useController<DocumentEntity<T>>({
    control: control,
    name: fieldName,
  });
  const [height, setHeight] = useState(14);

  const fieldValue = watch(fieldName);

  useEffect(() => {
    if (inputRef.current) {
      setHeight(inputRef.current.scrollHeight);
    }
  }, [fieldValue]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      setHeight(inputRef.current.scrollHeight);
    }
  }, [isEditing]);

  return (
    <EditableInput
      disabled={!isEditing}
      isEditing={isEditing}
      value={
        typeof field.value === "string" || typeof field.value === "number"
          ? field.value
          : ""
      }
      onBlur={() => {
        handleBlur && handleBlur(blockIndex);
        field.onBlur();
      }}
      onChange={field.onChange}
      ref={inputRef}
      style={{ ...style, height }}
      autoFocus
    />
  );
}

export default EditableTextArea;
