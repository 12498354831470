import {
  Controller,
  Path,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";

import {
  DocumentTypeFactory,
  DocumentEntityType,
  DocumentEntity,
} from "neword-core";

import GenericDescriptionInput from "./GenericDescriptionInput";

function BlogPostCaseStudyDescription() {
  return (
    <div className="w-full step-2-wt">
      <GenericDescriptionInput<
        DocumentEntity<DocumentEntityType.BLOG_POST_CASE_STUDY>
      >
        name="inputParams.description"
        label="תיאור הבעיה"
        placeholder="איזה אתגרים אתם או הלקוח שלכם חוויתם???"
      />
      <GenericDescriptionInput<
        DocumentEntity<DocumentEntityType.BLOG_POST_CASE_STUDY>
      >
        name="inputParams.solutionDescription"
        label="פתרון הבעיה"
        placeholder="כיצד התגברתם על האתגרים וכיצד פתרתם אותם???"
      />

      <GenericDescriptionInput<
        DocumentEntity<DocumentEntityType.BLOG_POST_CASE_STUDY>
      >
        name="inputParams.results"
        label="תוצאות?"
        placeholder="לאיזה תוצאות הגעתם? מה הצלחתם להשיג?"
      />
    </div>
  );
}

export default BlogPostCaseStudyDescription;
