import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React from "react";
import styled from "styled-components";
import { FacebookPage } from "../../../core/entities/facebookPage";
import appIntegrationService from "../../../core/services/appIntegration.service";
import { useParams } from "react-router-dom";
import { AppIntegrationApplicationType } from "../../../core/types/appIntegrationApplicationType";
import { AppIntegrationType } from "../../../core/types/appIntegrationType";
import { useRecoilState } from "recoil";
import { appIntegrationState } from "../../../state/appIntegrationState";

interface ModalProps {
  onClose: () => void;
  pages: FacebookPage[];
}

const Grid = styled.div`
  display: flex; /* Change to flex */
  flex-wrap: wrap; /* Allow wrapping of items */
  gap: 16px; /* Add gap between items */
  max-width: 600px;
  max-height: 400px;
  overflow-y: auto;
  justify-content: center;
`;

const Title = styled.h1`
  color: #0a2540;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 2.5rem */
  letter-spacing: -0.075rem;
  width: auto;
  text-align: center;
`;

const SubTitle = styled.h2`
  color: #425466;
  text-align: center;
  font-weight: 300;
`;

const WrapperX = styled.div`
  margin-top: 40px;
`;

const FacebookPagesModal: React.FC<ModalProps> = ({ onClose, pages }) => {
  const { websiteId } = useParams();
  const [appIntegrations, setAppIntegrations] = useRecoilState(
    appIntegrationState(websiteId as string)
  );

  const onClick = async (page: FacebookPage) => {
    const appIn = await appIntegrationService.createAppIntegrationByWebsiteId({
      websiteId: websiteId as string,
      appIntegrationApplicationType: AppIntegrationApplicationType.FACEBOOK,
      appIntegrationType: AppIntegrationType.POST,
      accessToken: page.access_token,
      isEnabled: true,
      externalId: page.id,
    });

    setAppIntegrations([...appIntegrations, appIn]);

    onClose();
  };

  return (
    <Dialog
      header={
        <WrapperX>
          <Title>עמודי פייסבוק</Title>
          <SubTitle>בחר עמוד פייסבוק (יחיד) לניהול</SubTitle>
        </WrapperX>
      }
      visible
      onHide={onClose}
      modal
    >
      <br />
      {
        <Grid>
          {pages.map((page) => (
            <div onClick={() => onClick(page)} key={page.id}>
              <InputText value={page.name} readOnly />
            </div>
          ))}
        </Grid>
      }
    </Dialog>
  );
};

export default FacebookPagesModal;
