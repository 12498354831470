import { useCallback, useEffect, useState } from "react";
import generatorService from "../../../core/services/generator.service";
import styled, { keyframes, css } from "styled-components"; // Corrected import

import { ReactComponent as LightningIcon } from "../../../assets/Icons/Lightning.svg";
import { ReactComponent as ReturnIcon } from "../../../assets/Icons/Sync.svg"; // Assuming you have a return icon

interface RephraseButtonProps {
  onChange: (value: string) => void;
  content: string;
  prompt: string;
  text: string;
  id: string;
}

const CreateWtihAi = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  span {
    font-size: 12px;
    color: ${(props: { loading: boolean }) =>
      props.loading ? "grey" : "var(--primary-purple)"};
    font-weight: bold;
  }
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const IconWrapper = styled.div`
  svg {
    width: 12px;
    height: 12px;
    ${(props: { loading: boolean }) =>
      props.loading &&
      css`
        animation: ${spinAnimation} 1s linear infinite;
        fill: grey;
      `}
  }
`;

const RephraseButton = (props: RephraseButtonProps) => {
  const [loading, setLoading] = useState(false);

  const handler = useCallback(
    (e: any) => props.onChange(props.content + e.detail),
    [props]
  );

  const endStreamHandler = useCallback(() => {
    props.onChange(props.content);
  }, [props]);

  useEffect(() => {
    document.addEventListener(`stream-${props.id}`, handler);
    document.addEventListener(`end-stream-${props.id}`, endStreamHandler);

    return () => {
      document.removeEventListener(`stream-${props.id}`, handler);
      document.removeEventListener(`end-stream-${props.id}`, endStreamHandler);
    };
  }, [endStreamHandler, handler, props.id]);

  const refraseWidget = () => {
    if (loading) return; // Prevent the function from running if it's already loading

    setLoading(true); // Set loading to true
    props.onChange(""); // Clear the content during the request

    generatorService
      .streamGptRes(props.prompt, props.content, props.id)
      .then(() => {
        setLoading(false); // Set loading to false when the request finishes
      })
      .catch(() => {
        setLoading(false); // Handle the error and stop loading
      });
  };

  return (
    <CreateWtihAi onClick={refraseWidget} className="flex" loading={loading}>
      <IconWrapper loading={loading}>
        {loading ? <ReturnIcon /> : <LightningIcon />} {/* Conditional icon */}
      </IconWrapper>
      <span>{props.text}</span>
    </CreateWtihAi>
  );
};

export default RephraseButton;
