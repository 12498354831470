import styled from "styled-components";
import Avatar, { genConfig } from "react-nice-avatar";
import { FieldValues, Path } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { audienceState } from "../../../state/audienceState";
import { useParams } from "react-router";
import { useMemo, useState } from "react";
import RadioGroup, { RadioOptionWrapper } from "./RadioGroup";
import { ReactComponent as AddIcon } from "../../../assets/Icons/PlusBorder.svg";
import { Theme } from "../../../core/theme/theme";
import CreateAudienceModal from "./createAudienceModal";

interface Props<T> {
  threeColumn?: boolean;
  fieldName: Path<T>;
}

const AddIconStyled = styled(AddIcon)`
  width: 20px;
  height: 20px;
`;

const InsideRadio = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 8px;
  font-size: 12px;

  span {
  }
`;

const RadioWrapper = styled.div`
  .singleradio {
    background: white;
  }
  span {
    color: var(--title-color);
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const AvatarHairThick = styled(Avatar)`
  svg:nth-child(2) {
    path {
    }
    left: 0;
  }

  svg:nth-child(4) {
    path {
    }
    left: 0;
  }
`;

const InsideWrapper = styled.div`
  min-height: 40px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InsideRadioAppendWrapper = styled(RadioOptionWrapper)`
  color: ${Theme.colors.border};
  background-color: white;
  height: 100%;
  width: 100%;
  svg {
    width: 20px;
    height: 20px;
    fill: ${Theme.colors.border};
  }
  :hover {
    color: ${Theme.colors.purple};
    svg {
      fill: ${Theme.colors.purple};
    }
  }
`;

function AudienceSelect<FormDataType extends FieldValues>(
  props: Props<FormDataType>
) {
  const { websiteId } = useParams();
  const [createAudienceModal, showCreateAudienceModal] = useState(false);
  const audiences = useRecoilValue(audienceState(websiteId as string));
  const options = useMemo(
    () => [
      ...audiences.map((audience) => ({
        value: audience.id,
        render: (
          <>
            <InsideRadio className="insideradio">
              <AvatarHairThick
                style={{ width: "2.5rem", height: "2.5rem" }}
                {...audience.imageProps}
              />
              <span>{audience.name}</span>
            </InsideRadio>
          </>
        ),
      })),
    ],
    [audiences]
  );

  options.push();

  return (
    <>
      <Wrapper>
        <RadioWrapper>
          <RadioGroup<FormDataType, string>
            ColumnNumber={"3"}
            OptionColumnNumber={"3"}
            fieldName={props.fieldName}
            options={options}
            addOption={
              <InsideRadioAppendWrapper
                onClick={() => {
                  showCreateAudienceModal(true);
                }}
              >
                <InsideRadio className="insideradio">
                  <InsideWrapper>
                    <AddIconStyled />
                    <strong>הוסף קהל</strong>
                  </InsideWrapper>
                </InsideRadio>
              </InsideRadioAppendWrapper>
            }
          />
        </RadioWrapper>
      </Wrapper>
      {createAudienceModal && (
        <CreateAudienceModal onHide={() => showCreateAudienceModal(false)} />
      )}
    </>
  );
}

export default AudienceSelect;
