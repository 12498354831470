import React from "react";
import styled from "styled-components";
import Link from "../../common/Link";
import { useRecoilValue } from "recoil";
import { useState, useMemo, useCallback } from "react";
import { userState } from "../../../state/userState";
import Badge from "../../common/Badge";
import { websiteState } from "../../../state/websitesState";
import { useParams } from "react-router";
import { ReactComponent as LightbulbIcon } from "../../../assets/Icons/Lightbulb.svg";
import IconDataBox from "../../../components/website/overview/IconDataBox";
import { ReactComponent as PlusIcon } from "../../../assets/Icons/Plus.svg";
import { ReactComponent as ViewsIcon } from "../../../assets/Icons/Views.svg";
import { ReactComponent as ArticleIcon } from "../../../assets/Icons/WebsiteIcons/Blog.svg";
import Button from "../../common/form/Button";
import GenerateArticleModal from "../../modals/generateArtical/GenerateArticleModal";
import Preloader from "../../common/Preloader";
import useNavigator from "../../../hooks/useNavigator";
import welcomeBG from "../../../assets/images/welcomeCardBg.png";
import InputStyle from "../../common/form/InputStyle";
import { InputText } from "primereact/inputtext";
import { AutoComplete } from "primereact/autocomplete";
import { DocumentEntityType, DocumentTypeFactory } from "neword-core";
import DocBoxes from "../../document/components/DocBoxes";
import { ContentCardIcons } from "./contentCards/iconMappings";
import documentsDictionary from "../../document/documentsDictionary";
import documentService from "../../../core/services/document.service";
import { Theme } from "../../../core/theme/theme";

const NoticeWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    width: 20px;
    height: 20px;
    path {
      fill: white !important;
    }
  }

  small {
    color: #d1d1d1;

    a {
      color: white;
    }
  }
`;

const CardStyle = styled.div`
  background-color: #bcb7fa;
  /* background-blend-mode: soft-light; */
  padding: 0 3rem;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  color: black;
  /* background-image: url(${welcomeBG}); */
  min-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* text-align: center; */

  h1 {
    font-family: "Assistant";
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.045rem;
    text-transform: capitalize;
    font-weight: bold;
  }

  h2 {
    position: relative;
    font-family: "Assistant";
    font-size: 38px;
    font-style: normal;
    font-weight: bold;
    line-height: 130%; /* 1rem */
    letter-spacing: -0.08rem;
    text-transform: capitalize;
  }
`;

const InputWrapper = styled(InputStyle)`
  margin-top: 32px;
  width: 550px;

  span {
    width: 100%;
  }
  input,
  input:hover {
    border: solid 1px var(--text-color) !important;
  }
`;

const StyledButton = styled(Button)`
  font-weight: bold;

  font-size: 12px;

  /* font-size: 14px; */
  background: var(--primary-purple);
  color: white;

  svg {
    fill: white;
    width: 15px;
    height: 15px;
  }
`;

const AutoCompleteItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;

  .p-dropdown {
    width: 100%;
  }

  .p-dropdown .p-dropdown-label.p-placeholder {
    font-weight: 400;
    font-family: "Assistant";
    font-size: 38px;
    font-weight: bold;
    text-transform: capitalize;
  }
`;

const DocumentCategoryTranslations = {
  SOCIAL_MEDIA: "מדיה חברתית",
  WEB: "אינטרנט",
  MAILING: "דיוור",
  PAID: "ממומן",
  BLOG: "בלוג",
};

interface Props {
  isCmsConnected: boolean;
  setSearchTerm: (term: string) => void;
}

const AutoCompleteSearchItem: React.FC<{
  docBox: (typeof DocBoxes)[0];
  highlightTitleTerm: string;
}> = ({ docBox, highlightTitleTerm }) => {
  const docName = useMemo(
    () =>
      highlightTitleTerm
        ? docBox.title.replace(
            new RegExp(highlightTitleTerm, "gi"),
            (match) =>
              `<span style='color:${Theme.colors.purple};'>${match}</span>`
          )
        : docBox.title,
    [docBox.title, highlightTitleTerm]
  );

  const titleHtml = useMemo(
    () => ({
      __html: docName,
    }),
    [docName]
  );

  const IconComponent = ContentCardIcons[docBox.platformType].icon;
  return (
    <AutoCompleteItemWrapper>
      <IconComponent />
      <p dangerouslySetInnerHTML={titleHtml}></p>
    </AutoCompleteItemWrapper>
  );
};

const groupItemsByCategory = (items: any) => {
  const groupedItems = items.reduce((acc: any, item: any) => {
    const category =
      DocumentTypeFactory[item.type as DocumentEntityType].category;
    const label =
      DocumentCategoryTranslations[
        category as unknown as keyof typeof DocumentCategoryTranslations
      ];
    if (!acc[label]) {
      acc[label] = [];
    }
    acc[label].push(item);
    return acc;
  }, {});

  return Object.entries(groupedItems).map(([category, items]) => ({
    category,
    items,
  }));
};

const WelcomeBackCard: React.FC<Props> = ({ isCmsConnected }) => {
  const user = useRecoilValue(userState);
  const { websiteId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [items, setItems] = useState(groupItemsByCategory(DocBoxes));
  const [showGenerateArticleModal, setShowGenerateArticleModal] =
    useState(false);

  const navigate = useNavigator();

  const createDoc = useCallback(
    async (type: DocumentEntityType) => {
      if (!documentsDictionary[type]) return;
      const docId = await documentService.createDocument(
        type,
        websiteId as string
      );
      navigate(`/document/${docId}`);
    },
    [websiteId]
  );

  return (
    <CardStyle>
      <div className="grid">
        <div className="col-12">
          <h1>היי, {user?.firstName} 👋 </h1>
          <h2>איזה תוכן ניצור היום? </h2>

          <InputWrapper>
            <AutoComplete
              value={searchTerm}
              field="title"
              placeholder="לדוגמה ״ביו לאינסטגרם״ או ״פוסט בלינקדאין״"
              delay={0}
              completeMethod={(e) =>
                setItems(
                  groupItemsByCategory(
                    DocBoxes.filter((item) =>
                      item.title.toLowerCase().includes(e.query.toLowerCase())
                    )
                  )
                )
              }
              onSelect={(e) => createDoc(e.value.type)}
              suggestions={items}
              onChange={(e) => setSearchTerm(e.target.value)}
              optionGroupLabel="category"
              optionGroupChildren="items"
              itemTemplate={(item) => (
                <AutoCompleteSearchItem
                  highlightTitleTerm={searchTerm}
                  docBox={item}
                />
              )}
            />
          </InputWrapper>

          {showGenerateArticleModal && (
            <React.Suspense fallback={<Preloader />}>
              <GenerateArticleModal
                onHide={() => setShowGenerateArticleModal(false)}
                onSubmit={() => {
                  setShowGenerateArticleModal(false);
                  navigate(`/articles`);
                }}
              />
            </React.Suspense>
          )}
        </div>
      </div>
    </CardStyle>
  );
};

export default WelcomeBackCard;
