import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { useFormContext, Controller } from "react-hook-form";
import styled from "styled-components";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import { BlockOutputProps } from "./types/blockOutputProps";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import { ReactComponent as Neword } from "../../../assets/Icons/Neword.svg";
import { ReactComponent as InstagramLogo } from "../../../assets/Icons/InstagramIcon.svg";
import InstagramPreview from "../../../assets/images/instagramPreview.png";
import { ReactComponent as InstagramLike } from "../../../assets/Icons/InstagramLike.svg";
import { BlockProps } from "./types/dictionaryProps";
import { RefProps } from "./types/refProps";
import EditableTextArea from "../components/EditableTextArea";

const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
  opacity: 0.5;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AcountText = styled.div`
  font-weight: 700;
  font-size: 12px;
  display: flex;
  flex-direction: row-reverse;
  font-family: "Open Sans";
  letter-spacing: -0.2px;
`;

const ContentWrapper = styled.div`
  font-size: 12.21px;
  display: flex;
`;

const LeftTopMenu = styled.div`
  opacity: 0.5;
  display: flex;
  align-items: center;
`;

const InstagramPreviewWrapper = styled.img`
  border-radius: 19.576px;
  margin-top: 10px;
  width: 100%;
`;

const FacebookPreviewWrapper = styled.div`
  border-radius: 19.576px;
  margin-top: 10px;
  width: 100%;
  background-color: #f6f9fc;
  height: 216px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const PurpelText = styled.div`
  color: var(--main-hebrew, #6300c1);
  text-align: center;
  font-family: "Noto Sans Hebrew";
  font-size: 16px;
  font-weight: 700;
`;
const ImageText = styled.div`
  color: "";
  text-align: center;
  font-family: "Noto Sans Hebrew";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 13.432px */
  letter-spacing: -0.366px;
`;

const InstagramCaptionBlock = forwardRef<RefProps, BlockProps>(
  ({ isEditing, blockIndex, handleBlur }, ref) => {
    const { websiteId } = useParams();
    const website = useRecoilValue(websiteState(websiteId as string));

    const form =
      useFormContext<DocumentEntity<DocumentEntityType.INSTAGRAM_POST_IDEA>>();
    const imageText = form.getValues(`output.${blockIndex}.imageDescription`);

    useImperativeHandle(ref, () => ({
      onCopyCustom: () => {
        const values = form.getValues();
        return values.output[blockIndex].imageDescription || "";
      },
    }));

    return (
      <Wrapper className="col-4">
        <TopSection>
          <InstagramLogo />
          <LeftTopMenu>
            <AcountText className="ml-1">{website?.name}</AcountText>
            <Neword />
          </LeftTopMenu>
        </TopSection>
        <FacebookPreviewWrapper>
          <PurpelText>תמונה לדוגמה:</PurpelText>
          <ImageText>{imageText}</ImageText>
        </FacebookPreviewWrapper>{" "}
        <IconsWrapper>
          <InstagramLike />
        </IconsWrapper>
        <AcountText className="mt-3 flex row-revers">
          {website?.name}
        </AcountText>
        <ContentWrapper className="mt-1">
          <EditableTextArea<DocumentEntityType.INSTAGRAM_POST_IDEA>
            fieldName={`output.${blockIndex}.ideaDescription`}
            handleBlur={handleBlur}
            isEditing={isEditing}
            blockIndex={blockIndex}
          />
        </ContentWrapper>
      </Wrapper>
    );
  }
);

export default InstagramCaptionBlock;
