import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import AudienceSelect from "../../common/form/AudienceSelect";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocumentTone from "../components/DocumentTone";
import BlogPostOpinionDescription from "./formComponentes/BlogPostOpinionDescription";
import { AccordionProps } from "./types/AccordionProps";
import BlogPostSharedSettings from "./formComponentes/BlogPostSharedSettings";
import BlogPostTipsDescription from "./formComponentes/BlogPostTipsDescription";
import BlogPostComparisonDescription from "./formComponentes/BlogPostComparisonDescription";
import BlogPostCaseStudyDescription from "./formComponentes/BlogPostCaseStudyDescription";
import CartAbandonmentMailDescription from "./formComponentes/CartAbandonmentMailDescription";
import GenericDescriptionInput from "./formComponentes/GenericDescriptionInput";
import DocChipInput from "./formComponentes/DocChipInput";

export const postAccordions: AccordionProps[] = [
  {
    title: "תיאור כללי לתוכן ברשת חברתית",
    content: (
      <div className="w-full step-2-wt">
        <GenericDescriptionInput<
          DocumentEntity<DocumentEntityType.INSTAGRAM_CAPTION>
        >
          name="inputParams.description"
          label="תיאור הפוסט"
          placeholder={`לדוגמה: פנייה לנמען בשם, התאמה של ההצעה להיסטוריה שלו, הדגשה שזו הצעה בלעדית ללקוחות נאמנים, הוספה המלצה אישית או תזכורת על פריטים בעגלה`}
          showQuickOptions
          showAttchment
        />
        <GenericDescriptionInput<
          DocumentEntity<DocumentEntityType.INSTAGRAM_CAPTION>
        >
          name="inputParams.postGoal"
          label="מטרת הפוסט"
          placeholder={`מה אתם מנסים להשיג? (לדוגמה, מודעות למותג, מעורבות, קידום מוצר, הכרזת אירוע)`}
          hideRephrase
          inputStyle={{ height: 50 }}
        />
      </div>
    ),
    fieldNames: [
      "inputParams.cartItems",
      "inputParams.supportContact",
      "inputParams.incentives",
    ],
  },

  {
    title: "הגדרות כלליות לפוסט",
    content: (
      <div className="w-full step-2-wt">
        <GenericDescriptionInput<
          DocumentEntity<DocumentEntityType.INSTAGRAM_CAPTION>
        >
          name="inputParams.cta"
          label="קריאה לפעולה (אופציונלי)"
          placeholder={`לדוגמה ״הרשמה לניוזלטר שלנו״ או ״הובלה לקניית  המוצר שלנו https://neword/product.ai”`}
          inputStyle={{ height: 50 }}
        />
        <DocChipInput<DocumentEntity<DocumentEntityType.INSTAGRAM_CAPTION>>
          name="inputParams.hashtags"
          label="האשטגים (אופציונלי)"
          placeholder="האם יש האשטאגים או אזכורים שתרצו לכלול?"
        />
        <GenericDescriptionInput<
          DocumentEntity<DocumentEntityType.INSTAGRAM_CAPTION>
        >
          name="inputParams.additionalInformation"
          label="מידע נוסף (אופציונלי)"
          placeholder="כל מידע נוסף שתרצו להוסיף"
          hideRephrase
        />
      </div>
    ),
  },

  {
    title: "קהל יעד",
    content: (
      <AudienceSelect<
        DocumentEntity<DocumentEntityType.CART_ABANDONMENT_EMAIL>
      > fieldName="inputParams.audienceId" />
    ),
    fieldNames: ["inputParams.audienceId"],
  },
  {
    title: "סגנון כתיבה",
    content: (
      <>
        <small className="pr-2 mb-3">* ניתן לבחור יותר מאחד</small>
        <DocumentTone<DocumentEntity<DocumentEntityType.CART_ABANDONMENT_EMAIL>>
          fieldName="inputParams.tone"
          threeColumn={true}
        />
      </>
    ),
    fieldNames: ["inputParams.tone"],
  },
];
