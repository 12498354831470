// CustomChip.tsx
import React from "react";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../../assets/Icons/Close.svg"; // Adjust the path as needed

// Define the type for props
interface CustomChipProps {
  label: string;
  removable?: boolean;
  onRemove?: () => void;
}

const CloseIconStyled = styled(CloseIcon)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  fill: #741fff; /* Adjust color as needed */
`;

const CustomChip: React.FC<CustomChipProps> = ({
  label,
  removable,
  onRemove,
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <span>{label}</span>
      {removable && onRemove && <CloseIconStyled onClick={onRemove} />}
    </div>
  );
};

export default CustomChip;
