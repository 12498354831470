import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import AudienceSelect from "../../common/form/AudienceSelect";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocumentTone from "../components/DocumentTone";
import BlogPostOpinionDescription from "./formComponentes/BlogPostOpinionDescription";
import { AccordionProps } from "./types/AccordionProps";
import GenericDescriptionInput from "./formComponentes/GenericDescriptionInput";

export const pushNotificationAccordions: AccordionProps[] = [
  {
    title: "תיאור התוכן",
    content: (
      <div className="w-full step-2-wt">
        <GenericDescriptionInput<
          DocumentEntity<DocumentEntityType.PUSH_NOTIFICATION>
        >
          name="inputParams.notificationGoal"
          label="מה המטרה של הודעת הפוש?"
          placeholder="מה המטרה של הודעת דחיפה זו (למשל, קידום מכירה, תזכורת למשתמשים על אירוע וכו')?"
          inputStyle={{ height: 50 }}
        />
        <GenericDescriptionInput<
          DocumentEntity<DocumentEntityType.PUSH_NOTIFICATION>
        >
          name="inputParams.mainMessage"
          label="איזה מסר מרכזי תרצו להעביר? (לא חובה)"
          placeholder="תתארו את המסר המרכזי אותו תרצו להעביר"
        />
        <GenericDescriptionInput<
          DocumentEntity<DocumentEntityType.PRODUCT_UPDATE_EMAIL>
        >
          name="inputParams.cta"
          label="מהי הקריאה לפעולה בהודעת הפוש?"
          placeholder="לדוגמה ״הרשמה לניוזלטר שלנו״ או ״הובלה לקניית  המוצר שלנו https://neword/product.ai”"
          inputStyle={{ height: 50 }}
          hideRephrase
        />
      </div>
    ),
    fieldNames: [
      "inputParams.cartItems",
      "inputParams.supportContact",
      "inputParams.incentives",
    ],
  },

  {
    title: "קהל יעד",
    content: (
      <AudienceSelect<
        DocumentEntity<DocumentEntityType.CART_ABANDONMENT_EMAIL>
      > fieldName="inputParams.audienceId" />
    ),
    fieldNames: ["inputParams.audienceId"],
  },
  {
    title: "סגנון כתיבה",
    content: (
      <>
        <small className="pr-2 mb-3">* ניתן לבחור יותר מאחד</small>
        <DocumentTone<DocumentEntity<DocumentEntityType.CART_ABANDONMENT_EMAIL>>
          fieldName="inputParams.tone"
          threeColumn={true}
        />
      </>
    ),
    fieldNames: ["inputParams.tone"],
  },
];
