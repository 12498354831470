import { Dialog } from "primereact/dialog";

import styled from "styled-components";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { useParams } from "react-router";
import Button from "../../common/form/Button";
import {
  documentState,
  documentsSummariesState,
} from "../../../state/documentState";
import documentService from "../../../core/services/document.service";

interface Props {
  onHide: () => void;
  onSubmit: () => void;
  documentId: string;
}

const StyledButton = styled(Button)`
  width: fit-content;
`;

const DeleteDocumentModal: React.FC<Props> = ({
  onHide,
  documentId,
  onSubmit,
}) => {
  const { websiteId } = useParams();

  const [documents, setDocuments] = useRecoilState(
    documentsSummariesState(websiteId as string)
  );

  const onSubmitInternal = async () => {
    setDocuments(documents?.filter((a) => a.id !== documentId));
    documentService.deleteDocument(documentId);
    onSubmit();
  };

  return (
    <Dialog
      modal
      visible
      header="מחק את התוכן שלך"
      onHide={onHide}
      footer={
        <StyledButton primary onClick={onSubmitInternal}>
          מחיקת תוכן{" "}
        </StyledButton>
      }
    >
      <p>האם אתה בטוח שברצונך למחוק את התוכן ?</p>
      <small>
        מחיקת הפוסט הזה תמחק אותו רק מחשבון Neword שלך ו לא ישפיע על ה-CMS שלך.
      </small>
    </Dialog>
  );
};

export default DeleteDocumentModal;
