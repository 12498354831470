import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import styled from "styled-components";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import { ReactComponent as Neword } from "../../../assets/Icons/BlueIcon.svg";
import { ReactComponent as WebsiteLogo } from "../../../assets/Icons/WebsiteLogo.svg";
import { BlockProps } from "./types/dictionaryProps";
import { RefProps } from "./types/refProps";
import EditableTextArea from "../components/EditableTextArea";

const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
`;

const SubjectWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const AnswerInputWrapper = styled.div`
  border-radius: 8px;
  background: var(--Card-Background, #f6f9fc);
  padding: 10px 20px;
  display: flex;
  align-items: center;
`;
const ProductNameWrapper = styled.div`
  border-radius: 8px;
  background: var(--Card-Background, #f6f9fc);
  padding: 10px 20px;
  text-align: center;
  color: #000;
  font-family: "Noto Sans Hebrew";
  font-size: 12.211px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 13.432px */
  letter-spacing: -0.366px;
`;

const WebsiteProductDescriptionBlock = forwardRef<RefProps, BlockProps>(
  ({ isEditing, blockIndex, handleBlur }, ref) => {
    const { websiteId } = useParams();
    const website = useRecoilValue(websiteState(websiteId as string));

    const wrapperRef = useRef<HTMLDivElement>(null);

    const { getValues, control } =
      useFormContext<
        DocumentEntity<DocumentEntityType.WEBSITE_PRODUCT_DESCRIPTION>
      >();

    useImperativeHandle(ref, () => ({
      onCopyCustom: () => {
        const value = getValues(`output.${blockIndex}`);
        return value.description;
      },
    }));

    return (
      <Wrapper ref={wrapperRef}>
        <ProductNameWrapper>תיאור מוצר</ProductNameWrapper>
        <SubjectWrapper className="mt-3">
          <AnswerInputWrapper className="w-full">
            <EditableTextArea<DocumentEntityType.WEBSITE_PRODUCT_DESCRIPTION>
              fieldName={`output.${blockIndex}.description`}
              blockIndex={blockIndex}
              isEditing={isEditing}
              style={{ color: "black" }}
              handleBlur={handleBlur}
            />
          </AnswerInputWrapper>
        </SubjectWrapper>
        <div className="flex mt-3 flex-row-reverse">
          <WebsiteLogo />
        </div>
      </Wrapper>
    );
  }
);

export default WebsiteProductDescriptionBlock;
