import { useRecoilValue } from "recoil";
import { documentsSummariesState } from "../../state/documentState";
import { useParams } from "react-router";
import Link from "../common/Link";
import DocumentBlock from "../document/DocumentBlock";

const DocsList: React.FC<{ searchTerm: string }> = ({ searchTerm }) => {
  const { websiteId } = useParams();
  const docs = useRecoilValue(documentsSummariesState(websiteId as string));
  return (
    <>
      {docs
        .filter((doc) => doc.name.includes(searchTerm))
        .map((doc) => (
          <DocumentBlock highlightTitleTerm={searchTerm} doc={doc} />
        ))}
    </>
  );
};

export default DocsList;
