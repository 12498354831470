import React, { useState } from "react";
import ArticlesList from "../../components/articles/ArticlesList";
import DocsList from "../../components/docs/DocsList";
import InputStyle from "../../components/common/form/InputStyle";
import { InputText } from "primereact/inputtext";

const ArticlesPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <>
      <div className="grid">
        <div className="col-8">
          <InputStyle>
            <span className="p-input-icon-right w-full">
              <i className="pi pi-search" />
              <InputText
                className="border-none"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                placeholder="חפש מאמר"
              />
            </span>
          </InputStyle>
        </div>
        <ArticlesList searchTerm={searchTerm} />
      </div>
    </>
  );
};

export default ArticlesPage;
