import React, { useState, ReactNode } from "react";
import styled from "styled-components";
import Button from "../../common/form/Button";
import { ReactComponent as CheckIcon } from "../../../assets/Icons/Check.svg";

type AccordionProps = {
  title: string;
  children: ReactNode;
  isOpen: boolean; // Optional prop to set initial open state
  showIcon?: boolean;
  onToggle: () => void; // Function to handle toggle, provided by parent
};

const AccordionItem = styled.div`
  padding: 15px;
  border-radius: 8px;
  border: 1px solid var(--border-color, #e6e6e6);
  margin-top: 17px;

  background: white;
`;

const AccordionTitle = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  color: var(--primary-purple);
  padding: 10px;
  font-weight: bold;
  user-select: none; // Prevent text selection
  font-family: "Assistant";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 20.128px */
  letter-spacing: -0.549px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;

  &::after {
    content: "${(props) => (props.isOpen ? "-" : "+")}";
    float: left;
    position: absolute;
    left: 10px;
    font-size: 18px;
  }
`;

const IconWrapper = styled.div`
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--border-color);
  border-radius: 50%;
  svg {
    fill: var(--primary-purple);
    width: 15px;
    height: 15px;
  }
`;

const AccordionContent = styled.div<{ isOpen: boolean }>`
  padding: 0px;

  max-height: ${(props) =>
    props.isOpen
      ? "1000px"
      : "0"}; // Adjust max-height according to your content needs
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out,
    margin-top 0.4s ease-in-out;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  margin-top: ${(props) => (props.isOpen ? "15px" : "0px")};
  overflow: ${(props) => (props.isOpen ? "none" : "hidden")};
`;

const ParamAccordion: React.FC<AccordionProps> = ({
  title,
  children,
  onToggle,
  isOpen,
  showIcon,
}) => {
  //   const [isActive, setIsActive] = useState(defaultOpen);

  return (
    <AccordionItem>
      <AccordionTitle isOpen={isOpen} onClick={onToggle}>
        <IconWrapper>
          {showIcon && <CheckIcon />}
          {/* {icon} */}
        </IconWrapper>
        {title}
      </AccordionTitle>
      <AccordionContent isOpen={isOpen}>{children}</AccordionContent>
    </AccordionItem>
  );
};

export default ParamAccordion;
