import styled from "styled-components";

const SuggestedKeyword = styled.a`
  display: inline-flex;
  padding: 5px 10px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  gap: 20px;
  border-radius: 6px;
  border: 1px solid var(--Input-BorderColor, #e6e6e6);
  color: rgb(0, 0, 0);
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  &:hover {
    border-color: var(--primary-title);
  }
`;

interface Props {
  onClickKeyword: (keyword: string) => void;
}

const suggestedContents = [
  {
    keyword: "🛒 קידום מוצר",
    description: `תבליט את היתרונות והתכונות הטובות של המוצר שלנו.

מוצר: [תיאור מפורט של המוצר שאותו תרצו לקדם]`,
  },
  {
    keyword: "📣 פוסט מיתוגי",
    description: `יצירת פוסט מיתוגי

ערכי מותג: [תאר את הערכים שהמותג שלנו מייצג]
מסר מרכזי: [מהו המסר המרכזי שאנחנו רוצים להעביר?]`,
  },
  {
    keyword: "🌟 הכרזה על מוצר",
    description: `שיתוף המוצר שלנו והצגת היתרונות שלו.

שם המוצר: [תאר את המוצר החדש שלנו]
תכונות עיקריות: [ציין את התכונות המרכזיות שמייחדות את המוצר שלנו]
היתרונות: [מדוע הלקוחות צריכים לבחור במוצר הזה?]`,
  },
  {
    keyword: "🏷️ קידום מבצע",
    description: `עידוד הלקוחות לנצל את המבצע המיוחד שלנו לפני שייגמר.

פרטי המבצע: [ציין את פרטי המבצע שלנו, כולל אחוזי הנחה או מוצרים משתתפים]
תוקף המבצע: [מתי המבצע שלנו נגמר?]
הנעה לפעולה: [כיצד הלקוחות יכולים לנצל את המבצע שלנו?]`,
  },
  {
    keyword: "✍️ ביקורת",
    description: `הצגת המלצה מלקוח

פרטי הלקוח: [שם ומקצוע]
מוצר: [תאר או הוסף מידע באמצעות 'הוסף מידע']
סיפור הצלחה: [כיצד המוצר שלך עזר ללקוח]
כתוב בגוף: [ראשון/שלישי]
`,
  },
  {
    keyword: "📅 הכרזה על אירוע",
    description: `צור עניין באירוע שלנו.

  פרטי האירוע: [ציין את פרטי האירוע שלנו]
  סיבה להגיע: [האם יש יתרונות או אורח מיוחד?]
  קישור לאירוע: [הוסף קישור לאירוע שלנו באתר או בפייסבוק]
  `,
  },

  {
    keyword: "🌴 עונתי / חג",
    description: `צור פוסט שמתאים לעונה או לחג הנוכחי.

שם העונה / החג: [ציין את שם העונה או החג שלנו]
קישור למוצר או שירות: [כיצד המוצר שלנו משתלב בעונה או בחג?]
הנעה לפעולה: [עודד את הלקוחות לפעול בהתאם לעונה או לחג]`,
  },
];

const ContentSuggestion: React.FC<Props> = ({ onClickKeyword }) => {
  const onClick = (content: { keyword: string; description: string }) => {
    onClickKeyword(content.description); // Pass only the keyword
  };

  return (
    <>
      {suggestedContents?.map((suggestedContent, index) => (
        <SuggestedKeyword key={index} onClick={() => onClick(suggestedContent)}>
          {suggestedContent.keyword}
        </SuggestedKeyword>
      ))}
    </>
  );
};

export default ContentSuggestion;
